import _jquery from "jquery";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

/*!
 * =============================================================
 * dropify v0.2.1 - Override your input files with style.
 * https://github.com/JeremyFagis/dropify
 *
 * (c) 2017 - Jeremy FAGIS <jeremy@fagis.fr> (http://fagis.fr)
 * =============================================================
 */
;

(function (root, factory) {
  exports = factory(_jquery);
})(exports, function ($) {
  var pluginName = "dropify";
  /**
   * Dropify plugin
   *
   * @param {Object} element
   * @param {Array} options
   */

  function Dropify(element, options) {
    if (!(window.File && window.FileReader && window.FileList && window.Blob)) {
      return;
    }

    var defaults = {
      defaultFile: "",
      maxFileSize: 0,
      minWidth: 0,
      maxWidth: 0,
      minHeight: 0,
      maxHeight: 0,
      showRemove: true,
      showLoader: true,
      showErrors: true,
      errorTimeout: 3000,
      errorsPosition: "overlay",
      imgFileExtensions: ["png", "jpg", "jpeg", "gif", "bmp"],
      maxFileSizePreview: "5M",
      allowedFormats: ["portrait", "square", "landscape"],
      allowedFileExtensions: ["*"],
      messages: {
        "default": "Drag and drop a file here or click",
        "replace": "Drag and drop or click to replace",
        "remove": "Remove",
        "error": "Ooops, something wrong happended."
      },
      error: {
        "fileSize": "The file size is too big ({{ value }} max).",
        "minWidth": "The image width is too small ({{ value }}}px min).",
        "maxWidth": "The image width is too big ({{ value }}}px max).",
        "minHeight": "The image height is too small ({{ value }}}px min).",
        "maxHeight": "The image height is too big ({{ value }}px max).",
        "imageFormat": "The image format is not allowed ({{ value }} only).",
        "fileExtension": "The file is not allowed ({{ value }} only)."
      },
      tpl: {
        wrap: "<div class=\"dropify-wrapper\"></div>",
        loader: "<div class=\"dropify-loader\"></div>",
        message: "<div class=\"dropify-message\"><span class=\"file-icon\" /> <p>{{ default }}</p></div>",
        preview: "<div class=\"dropify-preview\"><span class=\"dropify-render\"></span><div class=\"dropify-infos\"><div class=\"dropify-infos-inner\"><p class=\"dropify-infos-message\">{{ replace }}</p></div></div></div>",
        filename: "<p class=\"dropify-filename\"><span class=\"dropify-filename-inner\"></span></p>",
        clearButton: "<button type=\"button\" class=\"dropify-clear\">{{ remove }}</button>",
        errorLine: "<p class=\"dropify-error\">{{ error }}</p>",
        errorsContainer: "<div class=\"dropify-errors-container\"><ul></ul></div>"
      }
    };
    (this || _global).element = element;
    (this || _global).input = $((this || _global).element);
    (this || _global).wrapper = null;
    (this || _global).preview = null;
    (this || _global).filenameWrapper = null;
    (this || _global).settings = $.extend(true, defaults, options, (this || _global).input.data());
    (this || _global).errorsEvent = $.Event("dropify.errors");
    (this || _global).isDisabled = false;
    (this || _global).isInit = false;
    (this || _global).file = {
      object: null,
      name: null,
      size: null,
      width: null,
      height: null,
      type: null
    };

    if (!Array.isArray((this || _global).settings.allowedFormats)) {
      (this || _global).settings.allowedFormats = (this || _global).settings.allowedFormats.split(" ");
    }

    if (!Array.isArray((this || _global).settings.allowedFileExtensions)) {
      (this || _global).settings.allowedFileExtensions = (this || _global).settings.allowedFileExtensions.split(" ");
    }

    (this || _global).onChange = (this || _global).onChange.bind(this || _global);
    (this || _global).clearElement = (this || _global).clearElement.bind(this || _global);
    (this || _global).onFileReady = (this || _global).onFileReady.bind(this || _global);
    this.translateMessages();
    this.createElements();
    this.setContainerSize();
    (this || _global).errorsEvent.errors = [];

    (this || _global).input.on("change", (this || _global).onChange);
  }
  /**
   * On change event
   */


  Dropify.prototype.onChange = function () {
    this.resetPreview();
    this.readFile((this || _global).element);
  };
  /**
   * Create dom elements
   */


  Dropify.prototype.createElements = function () {
    (this || _global).isInit = true;

    (this || _global).input.wrap($((this || _global).settings.tpl.wrap));

    (this || _global).wrapper = (this || _global).input.parent();
    var messageWrapper = $((this || _global).settings.tpl.message).insertBefore((this || _global).input);
    $((this || _global).settings.tpl.errorLine).appendTo(messageWrapper);

    if (this.isTouchDevice() === true) {
      (this || _global).wrapper.addClass("touch-fallback");
    }

    if ((this || _global).input.attr("disabled")) {
      (this || _global).isDisabled = true;

      (this || _global).wrapper.addClass("disabled");
    }

    if ((this || _global).settings.showLoader === true) {
      (this || _global).loader = $((this || _global).settings.tpl.loader);

      (this || _global).loader.insertBefore((this || _global).input);
    }

    (this || _global).preview = $((this || _global).settings.tpl.preview);

    (this || _global).preview.insertAfter((this || _global).input);

    if ((this || _global).isDisabled === false && (this || _global).settings.showRemove === true) {
      (this || _global).clearButton = $((this || _global).settings.tpl.clearButton);

      (this || _global).clearButton.insertAfter((this || _global).input);

      (this || _global).clearButton.on("click", (this || _global).clearElement);
    }

    (this || _global).filenameWrapper = $((this || _global).settings.tpl.filename);

    (this || _global).filenameWrapper.prependTo((this || _global).preview.find(".dropify-infos-inner"));

    if ((this || _global).settings.showErrors === true) {
      (this || _global).errorsContainer = $((this || _global).settings.tpl.errorsContainer);

      if ((this || _global).settings.errorsPosition === "outside") {
        (this || _global).errorsContainer.insertAfter((this || _global).wrapper);
      } else {
        (this || _global).errorsContainer.insertBefore((this || _global).input);
      }
    }

    var defaultFile = (this || _global).settings.defaultFile || "";

    if (defaultFile.trim() !== "") {
      (this || _global).file.name = this.cleanFilename(defaultFile);
      this.setPreview(this.isImage(), defaultFile);
    }
  };
  /**
   * Read the file using FileReader
   *
   * @param  {Object} input
   */


  Dropify.prototype.readFile = function (input) {
    if (input.files && input.files[0]) {
      var reader = new FileReader();
      var image = new Image();
      var file = input.files[0];
      var srcBase64 = null;

      var _this = this || _global;

      var eventFileReady = $.Event("dropify.fileReady");
      this.clearErrors();
      this.showLoader();
      this.setFileInformations(file);
      (this || _global).errorsEvent.errors = [];
      this.checkFileSize();
      this.isFileExtensionAllowed();

      if (this.isImage() && (this || _global).file.size < this.sizeToByte((this || _global).settings.maxFileSizePreview)) {
        (this || _global).input.on("dropify.fileReady", (this || _global).onFileReady);

        reader.readAsDataURL(file);

        reader.onload = function (_file) {
          srcBase64 = _file.target.result;
          image.src = _file.target.result;

          image.onload = function () {
            _this.setFileDimensions((this || _global).width, (this || _global).height);

            _this.validateImage();

            _this.input.trigger(eventFileReady, [true, srcBase64]);
          };
        }.bind(this || _global);
      } else {
        this.onFileReady(false);
      }
    }
  };
  /**
   * On file ready to show
   *
   * @param  {Event} event
   * @param  {Bool} previewable
   * @param  {String} src
   */


  Dropify.prototype.onFileReady = function (event, previewable, src) {
    (this || _global).input.off("dropify.fileReady", (this || _global).onFileReady);

    if ((this || _global).errorsEvent.errors.length === 0) {
      this.setPreview(previewable, src);
    } else {
      (this || _global).input.trigger((this || _global).errorsEvent, [this || _global]);

      for (var i = (this || _global).errorsEvent.errors.length - 1; i >= 0; i--) {
        var errorNamespace = (this || _global).errorsEvent.errors[i].namespace;
        var errorKey = errorNamespace.split(".").pop();
        this.showError(errorKey);
      }

      if (typeof (this || _global).errorsContainer !== "undefined") {
        (this || _global).errorsContainer.addClass("visible");

        var errorsContainer = (this || _global).errorsContainer;
        setTimeout(function () {
          errorsContainer.removeClass("visible");
        }, (this || _global).settings.errorTimeout);
      }

      (this || _global).wrapper.addClass("has-error");

      this.resetPreview();
      this.clearElement();
    }
  };
  /**
   * Set file informations
   *
   * @param {File} file
   */


  Dropify.prototype.setFileInformations = function (file) {
    (this || _global).file.object = file;
    (this || _global).file.name = file.name;
    (this || _global).file.size = file.size;
    (this || _global).file.type = file.type;
    (this || _global).file.width = null;
    (this || _global).file.height = null;
  };
  /**
   * Set file dimensions
   *
   * @param {Int} width
   * @param {Int} height
   */


  Dropify.prototype.setFileDimensions = function (width, height) {
    (this || _global).file.width = width;
    (this || _global).file.height = height;
  };
  /**
   * Set the preview and animate it
   *
   * @param {String} src
   */


  Dropify.prototype.setPreview = function (previewable, src) {
    (this || _global).wrapper.removeClass("has-error").addClass("has-preview");

    (this || _global).filenameWrapper.children(".dropify-filename-inner").html((this || _global).file.name);

    var render = (this || _global).preview.children(".dropify-render");

    this.hideLoader();

    if (previewable === true) {
      var imgTag = $("<img />").attr("src", src);

      if ((this || _global).settings.height) {
        imgTag.css("max-height", (this || _global).settings.height);
      }

      imgTag.appendTo(render);
    } else {
      $("<i />").attr("class", "dropify-font-file").appendTo(render);
      $("<span class=\"dropify-extension\" />").html(this.getFileType()).appendTo(render);
    }

    (this || _global).preview.fadeIn();
  };
  /**
   * Reset the preview
   */


  Dropify.prototype.resetPreview = function () {
    (this || _global).wrapper.removeClass("has-preview");

    var render = (this || _global).preview.children(".dropify-render");

    render.find(".dropify-extension").remove();
    render.find("i").remove();
    render.find("img").remove();

    (this || _global).preview.hide();

    this.hideLoader();
  };
  /**
   * Clean the src and get the filename
   *
   * @param  {String} src
   *
   * @return {String} filename
   */


  Dropify.prototype.cleanFilename = function (src) {
    var filename = src.split("\\").pop();

    if (filename == src) {
      filename = src.split("/").pop();
    }

    return src !== "" ? filename : "";
  };
  /**
   * Clear the element, events are available
   */


  Dropify.prototype.clearElement = function () {
    if ((this || _global).errorsEvent.errors.length === 0) {
      var eventBefore = $.Event("dropify.beforeClear");

      (this || _global).input.trigger(eventBefore, [this || _global]);

      if (eventBefore.result !== false) {
        this.resetFile();

        (this || _global).input.val("");

        this.resetPreview();

        (this || _global).input.trigger($.Event("dropify.afterClear"), [this || _global]);
      }
    } else {
      this.resetFile();

      (this || _global).input.val("");

      this.resetPreview();
    }
  };
  /**
   * Reset file informations
   */


  Dropify.prototype.resetFile = function () {
    (this || _global).file.object = null;
    (this || _global).file.name = null;
    (this || _global).file.size = null;
    (this || _global).file.type = null;
    (this || _global).file.width = null;
    (this || _global).file.height = null;
  };
  /**
   * Set the container height
   */


  Dropify.prototype.setContainerSize = function () {
    if ((this || _global).settings.height) {
      (this || _global).wrapper.height((this || _global).settings.height);
    }
  };
  /**
   * Test if it's touch screen
   *
   * @return {Boolean}
   */


  Dropify.prototype.isTouchDevice = function () {
    return "ontouchstart" in window || navigator.MaxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
  };
  /**
   * Get the file type.
   *
   * @return {String}
   */


  Dropify.prototype.getFileType = function () {
    return (this || _global).file.name.split(".").pop().toLowerCase();
  };
  /**
   * Test if the file is an image
   *
   * @return {Boolean}
   */


  Dropify.prototype.isImage = function () {
    if ((this || _global).settings.imgFileExtensions.indexOf(this.getFileType()) != "-1") {
      return true;
    }

    return false;
  };
  /**
  * Test if the file extension is allowed
  *
  * @return {Boolean}
  */


  Dropify.prototype.isFileExtensionAllowed = function () {
    if ((this || _global).settings.allowedFileExtensions.indexOf("*") != "-1" || (this || _global).settings.allowedFileExtensions.indexOf(this.getFileType()) != "-1") {
      return true;
    }

    this.pushError("fileExtension");
    return false;
  };
  /**
   * Translate messages if needed.
   */


  Dropify.prototype.translateMessages = function () {
    for (var name in (this || _global).settings.tpl) {
      for (var key in (this || _global).settings.messages) {
        (this || _global).settings.tpl[name] = (this || _global).settings.tpl[name].replace("{{ " + key + " }}", (this || _global).settings.messages[key]);
      }
    }
  };
  /**
   * Check the limit filesize.
   */


  Dropify.prototype.checkFileSize = function () {
    if (this.sizeToByte((this || _global).settings.maxFileSize) !== 0 && (this || _global).file.size > this.sizeToByte((this || _global).settings.maxFileSize)) {
      this.pushError("fileSize");
    }
  };
  /**
   * Convert filesize to byte.
   *
   * @return {Int} value
   */


  Dropify.prototype.sizeToByte = function (size) {
    var value = 0;

    if (size !== 0) {
      var unit = size.slice(-1).toUpperCase(),
          kb = 1024,
          mb = kb * 1024,
          gb = mb * 1024;

      if (unit === "K") {
        value = parseFloat(size) * kb;
      } else if (unit === "M") {
        value = parseFloat(size) * mb;
      } else if (unit === "G") {
        value = parseFloat(size) * gb;
      }
    }

    return value;
  };
  /**
   * Validate image dimensions and format
   */


  Dropify.prototype.validateImage = function () {
    if ((this || _global).settings.minWidth !== 0 && (this || _global).settings.minWidth >= (this || _global).file.width) {
      this.pushError("minWidth");
    }

    if ((this || _global).settings.maxWidth !== 0 && (this || _global).settings.maxWidth <= (this || _global).file.width) {
      this.pushError("maxWidth");
    }

    if ((this || _global).settings.minHeight !== 0 && (this || _global).settings.minHeight >= (this || _global).file.height) {
      this.pushError("minHeight");
    }

    if ((this || _global).settings.maxHeight !== 0 && (this || _global).settings.maxHeight <= (this || _global).file.height) {
      this.pushError("maxHeight");
    }

    if ((this || _global).settings.allowedFormats.indexOf(this.getImageFormat()) == "-1") {
      this.pushError("imageFormat");
    }
  };
  /**
   * Get image format.
   *
   * @return {String}
   */


  Dropify.prototype.getImageFormat = function () {
    if ((this || _global).file.width == (this || _global).file.height) {
      return "square";
    }

    if ((this || _global).file.width < (this || _global).file.height) {
      return "portrait";
    }

    if ((this || _global).file.width > (this || _global).file.height) {
      return "landscape";
    }
  };
  /**
  * Push error
  *
  * @param {String} errorKey
  */


  Dropify.prototype.pushError = function (errorKey) {
    var e = $.Event("dropify.error." + errorKey);

    (this || _global).errorsEvent.errors.push(e);

    (this || _global).input.trigger(e, [this || _global]);
  };
  /**
   * Clear errors
   */


  Dropify.prototype.clearErrors = function () {
    if (typeof (this || _global).errorsContainer !== "undefined") {
      (this || _global).errorsContainer.children("ul").html("");
    }
  };
  /**
   * Show error in DOM
   *
   * @param  {String} errorKey
   */


  Dropify.prototype.showError = function (errorKey) {
    if (typeof (this || _global).errorsContainer !== "undefined") {
      (this || _global).errorsContainer.children("ul").append("<li>" + this.getError(errorKey) + "</li>");
    }
  };
  /**
   * Get error message
   *
   * @return  {String} message
   */


  Dropify.prototype.getError = function (errorKey) {
    var error = (this || _global).settings.error[errorKey],
        value = "";

    if (errorKey === "fileSize") {
      value = (this || _global).settings.maxFileSize;
    } else if (errorKey === "minWidth") {
      value = (this || _global).settings.minWidth;
    } else if (errorKey === "maxWidth") {
      value = (this || _global).settings.maxWidth;
    } else if (errorKey === "minHeight") {
      value = (this || _global).settings.minHeight;
    } else if (errorKey === "maxHeight") {
      value = (this || _global).settings.maxHeight;
    } else if (errorKey === "imageFormat") {
      value = (this || _global).settings.allowedFormats.join(", ");
    } else if (errorKey === "fileExtension") {
      value = (this || _global).settings.allowedFileExtensions.join(", ");
    }

    if (value !== "") {
      return error.replace("{{ value }}", value);
    }

    return error;
  };
  /**
   * Show the loader
   */


  Dropify.prototype.showLoader = function () {
    if (typeof (this || _global).loader !== "undefined") {
      (this || _global).loader.show();
    }
  };
  /**
   * Hide the loader
   */


  Dropify.prototype.hideLoader = function () {
    if (typeof (this || _global).loader !== "undefined") {
      (this || _global).loader.hide();
    }
  };
  /**
   * Destroy dropify
   */


  Dropify.prototype.destroy = function () {
    (this || _global).input.siblings().remove();

    (this || _global).input.unwrap();

    (this || _global).isInit = false;
  };
  /**
   * Init dropify
   */


  Dropify.prototype.init = function () {
    this.createElements();
  };
  /**
   * Test if element is init
   */


  Dropify.prototype.isDropified = function () {
    return (this || _global).isInit;
  };

  $.fn[pluginName] = function (options) {
    this.each(function () {
      if (!$.data(this || _global, pluginName)) {
        $.data(this || _global, pluginName, new Dropify(this || _global, options));
      }
    });
    return this || _global;
  };

  return Dropify;
});

export default exports;